import React, { useState, useEffect } from "react";
import "./Pagination.css";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const createPagination = () => {
    const pages = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 3) {
        pages.push(1);
        pages.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  };

  const [previsDisabled, setPrevIsDisabled] = useState(false);
  const [nextisDisabled, setNextIsDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputVisible, setIsInputVisible] = useState(false);

  useEffect(() => {
    if (currentPage === 1) {
      setPrevIsDisabled(true);
    } else {
      setPrevIsDisabled(false);
    }

    if (currentPage === totalPages) {
      setNextIsDisabled(true);
    } else {
      setNextIsDisabled(false);
    }
  }, [currentPage]);

  const handlePreviousPage = (e) => {
    e.preventDefault();
    onPageChange(currentPage - 1);
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    onPageChange(currentPage + 1);
  };

  const handlePageClick = (page) => {
    if (page === "...") {
      setIsInputVisible(true);
    } else {
      onPageChange(page);
      setIsInputVisible(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputSubmit = (e) => {
    if (e.key === "Enter") {
      const pageNumber = parseInt(inputValue);
      if (pageNumber && pageNumber > 0 && pageNumber <= totalPages) {
        onPageChange(pageNumber);
      }
      setInputValue("");
      setIsInputVisible(false);
    }
  };

  return (
    <div>
      <button
        className={`prevbut_req ${previsDisabled ? "disabled" : ""}`}
        onClick={handlePreviousPage}
        disabled={previsDisabled}
      >
        Previous
      </button>

      {createPagination().map((page, index) => (
        <React.Fragment key={index}>
          {page === "..." && isInputVisible ? (
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputSubmit}
              autoFocus
              style={{ width: "50px", border:"1px solid black", padding:"5px 10px 5px 10px"}}
            />
          ) : (
            <button
              className="pagination_style"
              onClick={() => handlePageClick(page)}
              style={{
                backgroundColor:
                  page === currentPage ? "var(--itdri3)" : "#fff",
                color: page === currentPage ? "#fff" : "#000",
              }}
            >
              {page}
            </button>
          )}
        </React.Fragment>
      ))}

      <button
        className={`nextbut_req ${nextisDisabled ? "disabled" : ""}`}
        onClick={handleNextPage}
        disabled={nextisDisabled}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
