import React from "react";
import "./Ebook.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import DropFileInput from "./DropFileInput.js";
import axios from "axios";
import Popup from "./PopUp.js";
import logo from "../assets/marshall2.png";
import gifLogo from "../assets/Book.gif";
import Spinner from "./SpinnerDs.js";
import Select from "react-select";
import Toast from "./Toast.js";
import { FaSearch } from "react-icons/fa"; // Import icon search

const { useState, useEffect } = React;

const Ebook = (props) => {
  // Get Current Date
  let newDate = new Date();
  let year = newDate.getFullYear();

  // Inisiasi Interval 3 detik
  const [start, setStart] = useState(false);

  // Loading Spinner
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  // Notification
  const [notifVis, setNotifVis] = useState(false);
  const [notifIsian, setNotifIsian] = useState("");

  // VISION KONTEN, LOADING dan HASIL DONE
  // const [kontenVis, setKontenVis] = useState(true);
  // const [loadingVis, setLoadingVis] = useState(false);
  // const [doneVis, setDoneVis] = useState(false);

  // TOKEN DAN STATUS EBOOK
  // const [tokenEbook, setTokenEbook] = useState("");
  // const [statusEbook, setStatusEbook] = useState("");

  // Options Dropdown
  const options_jenisEbook = ["Buku", "Jurnal", "Bahan Ajar"];
  const options_bahasa = ["Indonesia", "English"];
  // const options_grupKom = ["One", "Two", "Three"];
  // const options_namaKom = ["One", "Two", "Three"];

  // const [options_grupKom, setGrupkom] = useState([]);
  const [options_namaKom, setNamakom] = useState([]);

  // Variable Box dan Dropdown
  const [valjenisEbook, setValJenisEbook] = useState(options_jenisEbook[0]);
  const [valjudulEbook, setValJudulEbook] = useState("");
  const [valpenerbit, setValPenerbit] = useState("");
  const [valauthor, setValAuthor] = useState("");

  const [valtahun, setValTahun] = useState(year);
  const [valisbn, setValIsbn] = useState("");
  const [valdoi, setValDoi] = useState("");

  const [valbahasa, setValBahasa] = useState(options_bahasa[0]);
  const [valgrupkomEbook, setValGrupkomEbook] = useState("");
  const [valnamakomEbook, setValNamakomEbook] = useState("");

  const [files, setFiles] = useState("");

  const [rowsjudul, setRowsJudul] = useState("1");
  const [rowsauthor, setRowsAuthor] = useState("1");

  const [selectedGrup, setSelectedGrup] = useState(null);
  const [selectedNamkom, setSelectedNamkom] = useState(null);

  // Red Border
  const [redGrup, setRedGrup] = useState(false);
  const [redNamkom, setRedNamkom] = useState(false);
  const [redJenis, setRedJenis] = useState(false);
  const [redBahasa, setRedBahasa] = useState(false);

  const [redJudul, setRedJudul] = useState(false);
  const [redPenerbit, setRedPenerbit] = useState(false);
  const [redAuthor, setRedAuthor] = useState(false);

  const [redTahun, setRedTahun] = useState(false);
  const [redISBN, setRedISBN] = useState(false);
  const [redDoi, setRedDoi] = useState(false);

  const [redFile, setRedFile] = useState(false);

  // POP UP
  // const [showPopupHelp, setPopupHelp] = useState(false);

  const stylesDropSearch = {
    valueContainer: (base) => ({
      ...base,
      maxHeight: 70,
      overflowY: "auto",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "var(--itdri3)" : "gray", // Warna border saat fokus dan normal
      "&:hover": {
        borderColor: state.isFocused ? "var(--itdri3)" : "gray", // Warna border saat hover
      },
      boxShadow: state.isFocused ? "0 0 0 1px var(--itdri3)" : "none", // Shadow saat fokus
    }),
  };

  const [filterKomlist, setFilterKomList] = useState([]);
  useEffect(() => {
    console.log("Grup Komlist : " + props.grupKomlist);

    const formattedOptions = props.grupKomlist.map((item, index) => ({
      value: "grup" + (index + 1).toString(),
      label: item,
    }));

    setFilterKomList(formattedOptions);

    // setGrupkom(props.grupKomlist);
  }, []);

  const handleChangeValGrup = (e) => {
    setValGrupkomEbook(e.label);
    setSelectedGrup(e);

    const getNamaKom = async () => {
      const hasil = await getKompetensiByGrupKompetensi(e.label, 5);
      console.log("HASIL, ", hasil);
      setNamakom(hasil);
      setSelectedNamkom(hasil[0]);
      setValNamakomEbook(hasil[0].label);
    };

    getNamaKom();
  };

  const handleChangeValNamkom = (e) => {
    setValNamakomEbook(e.label);
    setSelectedNamkom(e);
  };

  const getKompetensiByGrupKompetensi = async (
    nama_grup_kompetensi,
    maxAttempts
  ) => {
    let arrayStore = [];
    let attempts = 0;
    const formData = new FormData();
    formData.append("nama_grup_kompetensi", nama_grup_kompetensi);

    while (arrayStore.length === 0 && attempts < maxAttempts) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL +
            "/get-list-kompetensi-by-grup-kompetensi/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (
          response.data.list_kompetensi &&
          response.data.list_kompetensi.length > 0
        ) {
          let data = response.data.list_kompetensi;
          arrayStore = data.map((item, index) => ({
            value: "namkom" + (index + 1).toString(),
            label: item[1],
          }));

          console.log(arrayStore);
          break; // Hentikan loop jika token berhasil diambil
        } else {
          console.log("Data kosong, mencoba lagi...");
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      attempts += 1;
    }

    if (arrayStore.length === 0) {
      console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
    }

    return arrayStore;
  };

  const [errorMsg, setErrorMsg] = useState("");
  const [prosesUpload, setProsesUpload] = useState("0");

  const createEbook = async (formData, maxAttempts) => {
    let statusStore = "";
    let attempts = 0;

    const uploadEbook = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/new-vector-ebook/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 1200000, // 20 Menit
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(`Progress: ${percentCompleted}%`);
              if (percentCompleted < 60) {
                setLoadingSpinner(true);
                setProsesUpload(percentCompleted);
              }
              // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
            },
          }
        );

        if (
          response.data.request_token &&
          response.data.request_token.length > 0
        ) {
          props.toggleVisEbook("content", false);
          props.toggleVisEbook("loading", true);
          props.toggleVisEbook("done", false);
          setLoadingSpinner(false);

          const data = response.data.request_token;
          const status = response.data.request_status;

          props.toggleVisEbook("token", data);
          props.toggleVisEbook("status", status);
          statusStore = status;

          // console.log("TOKEN EBOOK NYA:", data);
          console.log("STATUS EBOOK NYA:", status);

          return true; // Selesai dengan sukses
        } else {
          console.log("Gagal Upload, mencoba lagi...");
          return false; // Gagal, akan mencoba lagi
        }
      } catch (error) {
        console.error("Error message:", error.message);

        if (error.response) {
          console.error("Server responded with:", error.response.status);
          setErrorMsg("Server responded with:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
          setErrorMsg("No response received:", error.request);
        } else {
          console.error("Axios config error:", error.message);
          setErrorMsg("Axios config error:", error.message);
        }

        return false; // Error, akan mencoba lagi
      }
    };

    while (statusStore === "" && attempts < maxAttempts) {
      attempts += 1;
      const isSuccessful = await uploadEbook();

      if (isSuccessful) {
        setStart(true);
        break;
      } else if (attempts < maxAttempts) {
        console.log(
          `Menunggu 10 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
        );
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    }

    if (statusStore === "") {
      console.log(`Gagal Upload Ebook setelah ${maxAttempts} percobaan.`);
    }

    return statusStore;
  };

  // function getKompetensiByGrupKompetensi(nama_grup_kompetensi) {
  //   // ===================================================
  //   // Call List Kompetensi By Nama Grup Kompetensi
  //   const formData = new FormData();
  //   formData.append("nama_grup_kompetensi", nama_grup_kompetensi);

  //   axios({
  //     timeout: 360000,
  //     method: "post",
  //     url: process.env.REACT_APP_API_URL+"/get-list-kompetensi-by-grup-kompetensi/",
  //     data: formData,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then(function (res) {
  //       let data = res.data.list_kompetensi;

  //       for (let i = 0; i < data.length; i++) {
  //         grupArray.push(data[i][1]);
  //       }

  //       console.log("Nama Kompetensi : " + grupArray);
  //       setNamakom(grupArray);
  //       setValNamakomEbook(grupArray[0]);
  //       // return grupArray;
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       return "";
  //     });

  //   // ===================================================
  // }

  const [namaEbook, setNamaEbook] = useState("");

  const handleselectedFile = (e) => {
    const file = e.target.files[0];

    if (file && file.type !== "application/pdf") {
      // alert("Hanya file dengan format .pdf yang diperbolehkan.");
      setNotifVis(true);
      setNotifIsian("Hanya file dengan format .pdf yang diperbolehkan.");
      e.target.value = null; // Mengosongkan input jika file tidak valid
      return;
    }

    if (file) {
      if (e.target.files[0] != undefined) {
        console.log(e.target.files[0].name);

        const data = e.target.files[0].name;
        setNamaEbook(data);
        setFiles(e.target.files[0]);

        console.log("FILE PDF : ", e.target.files[0]);
      }
    }

    if (file) {
      // Membaca beberapa byte pertama dari file untuk memeriksa apakah itu PDF
      const reader = new FileReader();
      reader.onloadend = () => {
        const arr = new Uint8Array(reader.result).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        // Memeriksa header file PDF yang biasanya dimulai dengan "25 50 44 46" atau "%PDF"
        if (header === "25504446") {
          console.log("File valid PDF:", file.name);
        } else {
          // alert("File bukan PDF yang valid!");
          setNotifVis(true);
          setNotifIsian("File bukan PDF yang valid!");
          e.target.value = null; // Mengosongkan input jika file tidak valid
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      // alert("Tidak ada file yang dipilih.");
      setNotifVis(true);
      setNotifIsian("Tidak ada file yang dipilih.");
    }
  };

  const handleClickUpload = (e) => {
    // Something
    e.preventDefault();

    // Log Console
    console.log("==================================");
    console.log("Jenis Ebook : " + valjenisEbook);
    console.log("Judul Ebook : " + valjudulEbook);
    console.log("Penerbit : " + valpenerbit);
    console.log("Author : " + valauthor);
    console.log("==================================");

    console.log("Tahun : " + valtahun);
    console.log("ISBN : " + valisbn);
    console.log("DOI : " + valdoi);
    console.log("==================================");

    console.log("Bahasa : " + valbahasa);
    console.log("Grup Kompetensi : " + valgrupkomEbook);
    console.log("Nama Kompetensi : " + valnamakomEbook);

    console.log("==================================");

    console.log("Files : ", files);
    console.log("USER ID : ", props.userid);

    if (valgrupkomEbook === "" || valnamakomEbook === "" || valjenisEbook === "" || valbahasa === "" || valjudulEbook === "" || valpenerbit === "" || valtahun === "" || valisbn === "" || valdoi === "" || files === "") {
      // alert("Judul, Kompetensi dan File PDF Tidak Boleh Kosong");
      setNotifVis(true);
      setNotifIsian("Semua Inputan Upload Ebook Harus di Isi Terlebih Dahulu");

      if (valgrupkomEbook == "") setRedGrup(true); setTimeout((()=>{setRedGrup(false)}), 2000);
      if (valnamakomEbook == "") setRedNamkom(true); setTimeout((()=>{setRedNamkom(false)}), 2000);
      if (valjenisEbook == "") setRedJenis(true); setTimeout((()=>{setRedJenis(false)}), 2000);
      if (valbahasa == "") setRedBahasa(true); setTimeout((()=>{setRedBahasa(false)}), 2000);
      if (valjudulEbook == "") setRedJudul(true); setTimeout((()=>{setRedJudul(false)}), 2000);
      if (valpenerbit == "") setRedPenerbit(true); setTimeout((()=>{setRedPenerbit(false)}), 2000);
      if (valauthor == "") setRedAuthor(true); setTimeout((()=>{setRedAuthor(false)}), 2000);
      if (valtahun == "") setRedTahun(true); setTimeout((()=>{setRedTahun(false)}), 2000);
      if (valisbn == "") setRedISBN(true); setTimeout((()=>{setRedISBN(false)}), 2000);
      if (valdoi == "") setRedDoi(true); setTimeout((()=>{setRedDoi(false)}), 2000);
      if (files == "") setRedFile(true); setTimeout((()=>{setRedFile(false)}), 2000);

    } else {
      props.toggleVisEbook("content", false);
      props.toggleVisEbook("loading", false);
      props.toggleVisEbook("done", false);

      const formData = new FormData();
      formData.append("jenis_ebook", valjenisEbook);
      formData.append("judul_ebook", valjudulEbook);
      formData.append("penerbit", valpenerbit);
      formData.append("author", valauthor);
      formData.append("tahun_terbit", valtahun);

      if (valisbn == "") {
        formData.append("isbn", "-");
      } else {
        formData.append("isbn", valisbn);
      }

      if (valdoi == "") {
        formData.append("doi", "-");
      } else {
        formData.append("doi", valdoi);
      }

      formData.append("bahasa", valbahasa);
      formData.append("grup_kompetensi", valgrupkomEbook);
      formData.append("kompetensi", valnamakomEbook);
      formData.append("file_ebook", files);
      formData.append("id_user", props.userid);

      const uploadEbook = async () => {
        const hasil = await createEbook(formData, 5);
        if (hasil === "" || hasil === null) {
          // alert("GAGAL UPLOAD EBOOK => " + errorMsg);
          setNotifVis(true);
          setNotifIsian("GAGAL UPLOAD EBOOK => " + errorMsg);
        }
      };

      uploadEbook();
    }

    // axios({
    //   timeout: 360000,
    //   method: "post",
    //   url: process.env.REACT_APP_API_URL+"/new-vector-ebook/",
    //   data: formData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // })
    //   .then(function (res) {
    //     let data = res.data.status;
    //     console.log("STATUS EBOOK NYA : ", data);
    //     setStatusEbook(data);

    //     setKontenVis(false);
    //     setLoadingVis(true);
    //     setDoneVis(false);

    //     // PROPS
    //     // props.getDataModule("kontenVis", false);
    //     // props.getDataModule("loadingVis", true);
    //     // props.getDataModule("doneVis", false);
    //   })
    //   .catch(function (res) {
    //     console.log(res);
    //     return "";
    //   });

    // setKontenVis(false);
    // setLoadingVis(true);

    // setTimeout(() => {
    //   setLoadingVis(false);
    //   setDoneVis(true);
    // }, 2000);
  };

  // SET VISION BERDASARKAN STATUS EBOOK
  useEffect(() => {
    console.log("Settings Loading dengan Status");
    console.log("+" + props.status + "+");

    if (props.status === "done") {
      console.log("DONEEEEEEEEEEEEEEEEEEEEEE");
      // setKontenVis(false);
      // setLoadingVis(false);
      // setDoneVis(true);

      props.toggleVisEbook("content", false);
      props.toggleVisEbook("loading", false);
      props.toggleVisEbook("done", true);

      // Props (AGAR KETIKA PINDAH PAGE VIS TERSIMPAN)
      // props.getDataModule("loadingVis", false);
      // props.getDataModule("doneVis", true);
      // props.getDataModule("kontenVis", false);
    }
    // Jika selain "Done"
    else if (props.status === "processing") {
      console.log("PROCESINGGGGGGGGGGGGGG");
      // setKontenVis(false);
      // setLoadingVis(true);
      // setDoneVis(false);

      props.toggleVisEbook("content", false);
      props.toggleVisEbook("loading", true);
      props.toggleVisEbook("done", false);

      // Props
      // props.getDataModule("loadingVis", true);
      // props.getDataModule("doneVis", false);
      // props.getDataModule("kontenVis", false);
    } else if (props.status === "waiting") {
      console.log("WAITINGGGGGGGGGGGGGGGGGGGGGGG");
      // setKontenVis(false);
      // setLoadingVis(true);
      // setDoneVis(false);

      props.toggleVisEbook("content", false);
      props.toggleVisEbook("loading", true);
      props.toggleVisEbook("done", false);

      // Props
      // props.getDataModule("loadingVis", true);
      // props.getDataModule("doneVis", false);
      // props.getDataModule("kontenVis", false);
    } else if (props.status === "error") {
      console.log("ERRORRRRRRRRRRR");
      // setKontenVis(true);
      // setLoadingVis(false);
      // setDoneVis(false);

      props.toggleVisEbook("content", true);
      props.toggleVisEbook("loading", false);
      props.toggleVisEbook("done", false);

      // Props
      // props.getDataModule("loadingVis", false);
      // props.getDataModule("doneVis", false);
      // props.getDataModule("kontenVis", true);

      // alert("STATUS ERROR : BUKU BERMASALAH");
      setNotifVis(true);
      setNotifIsian("STATUS ERROR : BUKU BERMASALAH");
    } else if (props.status === "RESET") {
      console.log("INI KOSONGGGGGGGGGGGG");
      props.toggleVisEbook("content", true);
      props.toggleVisEbook("loading", false);
      props.toggleVisEbook("done", false);
    }

    // if (clickGenerate) {

    // }
  }, [props.status]);

  const [showJudulEbook, setShowJudulEbook] = useState("");
  const [showJenisEbook, setShowJenisEbook] = useState("");

  // Ambil Status Generate per interval 3 detik (KHUSUS REVIEW BUTTON)

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("request_token", props.token);

    // console.log("Token Mapping : ", tokenMapping);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/result-vector-ebook/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
        }
      );
      let data = res.data.status;
      console.log("Status Ebook : ", data);

      let hasil = res.data.result;
      // console.log(hasil);

      let book = hasil.judul_ebook;
      let jenis = hasil.jenis_ebook;
      console.log("Judul Ebook : ", book);
      console.log("Jenis Ebook : ", jenis);
      props.toggleVisEbook("status", data);
      setShowJudulEbook(book);
      setShowJenisEbook(jenis);

      if (data === "done") {
        console.log("Proses Selesai");
        setStart(false);
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  useEffect(() => {
    let interval;

    if (start) {
      interval = setInterval(async () => {
        await fetchData(); // Call the fetchData function here
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [start, props.token]);

  // useEffect(() => {
  //   const fetchData = () => {
  //     // console.log("Token DB : ", props.token);
  //     // console.log("Token Module : ", tokenGenerate);
  //     const formData = new FormData();
  //     formData.append("request_token", props.token);

  //     axios({
  //       timeout: 360000,
  //       method: "post",
  //       url: process.env.REACT_APP_API_URL + "/result-vector-ebook/",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //       .then(function (res) {
  //         let data = res.data.status;
  //         console.log("Status Ebook : ", data);
  //         props.toggleVisEbook("status", data);

  //         let hasil = res.data.result;
  //         // console.log(hasil);

  //         let book = hasil.judul_ebook;
  //         let jenis = hasil.jenis_ebook;
  //         console.log("Judul Ebook : ", book);
  //         console.log("Jenis Ebook : ", jenis);
  //         setShowJudulEbook(book);
  //         setShowJenisEbook(jenis);
  //       })
  //       .catch(function (res) {
  //         console.log(res);
  //         return "";
  //       });
  //   };

  //   // Fetch data immediately on mount
  //   fetchData();

  //   // Set up interval to fetch data every 3 seconds
  //   const interval = setInterval(fetchData, 3000);

  //   // Clean up interval on unmount
  //   return () => clearInterval(interval);
  // }, [props.token]);

  const clickBaru = (e) => {
    e.preventDefault();

    // Konten Null
    setValJenisEbook(options_jenisEbook[0]);
    setValJudulEbook("");
    setValPenerbit("");
    setValAuthor("");
    setValTahun(year);
    setValIsbn("");
    setValDoi("");
    setValBahasa(options_bahasa[0]);
    setValGrupkomEbook("");
    setValNamakomEbook("");
    setFiles("");

    // setDoneVis(false);
    // setKontenVis(true);
    props.toggleVisEbook("token", "RESET");
    props.toggleVisEbook("status", "RESET");

    props.toggleVisEbook("content", true);
    props.toggleVisEbook("done", false);
  };

  useEffect(() => {
    props.getOverflow("hidden");
  }, []);

  const testClick = (e) => {
    e.preventDefault();
    console.log("Jenis Ebook : ", valjenisEbook);
  };

  const [classGrup, setClassGrup] = useState("");
  const [classNama, setClassNama] = useState("");
  const [classJenis, setClassJenis] = useState("");
  const [classBahasa, setClassBahasa] = useState("");

  const handleDropdown1Click = () => {
    setRowsJudul("1");
    setClassGrup("dropdown_open");
    setClassNama("dropdown_close");
    setClassJenis("dropdown_close");
    setClassBahasa("dropdown_close");
  };

  const handleDropdown2Click = () => {
    setRowsJudul("1");
    setClassGrup("dropdown_close");
    setClassNama("dropdown_open");
    setClassJenis("dropdown_close");
    setClassBahasa("dropdown_close");
  };

  const handleDropdown3Click = () => {
    setRowsJudul("1");
    setClassGrup("dropdown_close");
    setClassNama("dropdown_close");
    setClassJenis("dropdown_open");
    setClassBahasa("dropdown_close");
  };

  const handleDropdown4Click = () => {
    setRowsJudul("1");
    setClassGrup("dropdown_close");
    setClassNama("dropdown_close");
    setClassJenis("dropdown_close");
    setClassBahasa("dropdown_open");
  };

  // Change Val Tahun
  const onChangeTahun = (e) => {
    const inputValue = e.target.value;

    // Jika input kosong, biarkan kosong
    if (inputValue === "") {
      setValTahun("");
      return;
    }

    // Mengubah nilai input menjadi angka
    const newVal = parseInt(inputValue, 10);

    // Validasi input angka
    if (isNaN(newVal)) {
      setValTahun(year);
    } else if (newVal > year) {
      setValTahun(year);
    } else {
      setValTahun(newVal);
    }
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setValTahun(year);
    } else if (e.target.value < 1900) {
      setValTahun(1900);
    }
  };

  const handleInputISBN = (e) => {
    const inputValue = e.target.value;
    // Hanya mengizinkan angka dan tanda hubung (-)
    const regex = /^[0-9-]*$/;
    if (regex.test(inputValue)) {
      setValIsbn(inputValue);
    }
  };

  const handleInputDOI = (e) => {
    const inputValue = e.target.value;
    setValDoi(inputValue);
    // Hanya mengizinkan angka dan tanda hubung (-)
    // const regex = /^[0-9-]*$/;
    // if (regex.test(inputValue)) {
    //   setValDoi(inputValue);
    // }
  };

  const handleKeyDownJudul = (event) => {
    if (event.key === "Tab") {
      setRowsJudul("1");
    }
  };

  const handleKeyDownAuthor = (event) => {
    if (event.key === "Tab") {
      setRowsAuthor("1");
    }
  };

  const handleClickJudul = () => {
    setRowsJudul("5");
    setRowsAuthor("1");
  };

  const handleClickAuthor = () => {
    setRowsAuthor("5");
    setRowsJudul("1");
  };

  const handleClickDefault = () => {
    setRowsJudul("1");
    setRowsAuthor("1");
  };

  // Handle Close Toast
  const handleClose = () => {
    setNotifVis(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {notifVis && <Toast isiNotif={notifIsian} onClose={handleClose} />}

      {props.kontenVis && (
        <div style={{ height: "500px", position: "relative" }}>
          {/* <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <h1 style={{fontSize:"30px"}}>Sedang Perbaikan..</h1>
          </div> */}
          <h1 className="judulModul" onClick={testClick}>
            Insert Ebook
          </h1>
          <form action="" className="main-content-ebook">
            <form action="" className="baris-satu">
              <span className="span-items">Grup Kompetensi</span>
              <span className="required"> *</span>
              <div onClick={handleDropdown1Click}>
                {/* <Dropdown
                  className={`drop_grup ${classGrup}`}
                  options={props.grupKomlist}
                  placeholder="Select an option"
                  onChange={(e) => handleChangeValGrup(e)}
                /> */}

                <Select
                  className={redGrup ? `drop_grup ${classGrup} red` : `drop_grup ${classGrup}`}
                  options={filterKomlist}
                  placeholder={<div style={{display:"flex", alignItems:"center"}}><FaSearch style={{color: "gray", height:"12px"}} /> <label style={{marginLeft:"10px", fontSize:"12px", fontFamily: "'Poppins', sans-serif" }}>Search Grup Kompetensi</label></div>}
                  onChange={handleChangeValGrup}
                  noOptionsMessage={() => "Grup Kompetensi Tidak di temukan"}
                  styles={stylesDropSearch}
                  value={selectedGrup}
                />
              </div>

              <span className="span-items">Nama Kompetensi</span>
              <span className="required"> *</span>
              <div onClick={handleDropdown2Click}>
                {/* <Dropdown
                  className={`drop_nama ${classNama}`}
                  options={options_namaKom}
                  placeholder="Select an option"
                  value={valnamakomEbook}
                  onChange={(e) => setValNamakomEbook(e.value)}
                /> */}

                <Select
                  className={redNamkom ? `drop_nama ${classNama} red` : `drop_nama ${classNama}`}
                  options={options_namaKom}
                  placeholder={<div style={{display:"flex", alignItems:"center"}}><FaSearch style={{color: "gray", height:"12px"}} /> <label style={{marginLeft:"10px", fontSize:"12px", fontFamily: "'Poppins', sans-serif" }}>Search Nama Kompetensi</label></div>}
                  onChange={handleChangeValNamkom}
                  noOptionsMessage={() => "Nama Kompetensi Tidak di temukan"}
                  styles={stylesDropSearch}
                  value={selectedNamkom}
                />
              </div>

              <span className="span-items">Jenis Ebook</span>
              <div onClick={handleDropdown3Click}>
                <Dropdown
                  className={redJenis ? `drop_jenis_ebook ${classJenis} red` : `drop_jenis_ebook ${classJenis}`}
                  options={options_jenisEbook}
                  placeholder="Select an option"
                  value={valjenisEbook}
                  onChange={(e) => setValJenisEbook(e.value)}
                />
              </div>

              <span className="span-items">Bahasa</span>
              <div onClick={handleDropdown4Click}>
                <Dropdown
                  className={redBahasa ? `drop_bahasa ${classBahasa} red` : `drop_bahasa ${classBahasa}`}
                  options={options_bahasa}
                  placeholder="Select an option"
                  value={valbahasa}
                  onChange={(e) => setValBahasa(e.value)}
                />
              </div>
            </form>

            <form action="" className="baris-dua">
              <span className="span-items">Judul Ebook</span>
              <span className="required"> *</span>
              <textarea
                id="judulebook"
                className={redJudul ? "inputText_topik red" : "inputText_topik"}
                value={valjudulEbook}
                onClick={handleClickJudul}
                onChange={(e) => setValJudulEbook(e.target.value)}
                spellCheck={false}
                rows={rowsjudul} // Mengatur jumlah baris yang terlihat
                cols="50" // Mengatur lebar textarea
                style={{ whiteSpace: "pre-wrap" }} // Membungkus teks ke bawah
                // onBlur={handleBlurTopik2}
                placeholder="Input Judul Module disini"
                onKeyDown={handleKeyDownJudul}
              />
              {/* <input
                type="text"
                className="inputText"
                value={valjudulEbook}
                onChange={(e) => setValJudulEbook(e.target.value)}
              ></input> */}

              <br />
              <span className="span-items">Penerbit</span>
              <input
                type="text"
                className={redPenerbit ? "inputText red" : "inputText"}
                value={valpenerbit}
                onClick={handleClickDefault}
                onChange={(e) => setValPenerbit(e.target.value)}
                placeholder="Input nama Penerbit disini"
              ></input>

              <br />
              <span className="span-items">Author</span>
              {/* <input
                type="text"
                className="inputText"
                value={valauthor}
                onClick={handleClickDefault}
                onChange={(e) => setValAuthor(e.target.value)}
              ></input> */}
              <textarea
                id="authorebook"
                className={redAuthor ? "inputText_topik red" : "inputText_topik"}
                value={valauthor}
                onClick={handleClickAuthor}
                onChange={(e) => setValAuthor(e.target.value)}
                spellCheck={false}
                rows={rowsauthor} // Mengatur jumlah baris yang terlihat
                cols="50" // Mengatur lebar textarea
                style={{ whiteSpace: "pre-wrap" }} // Membungkus teks ke bawah
                // onBlur={handleBlurTopik2}
                placeholder="author1, author2 dan author3"
                onKeyDown={handleKeyDownAuthor}
              />
            </form>

            <form action="" className="baris-tiga">
              <span className="span-items">Tahun Terbit</span>
              <input
                type="number"
                className={redTahun ? "inputText red" : "inputText"}
                value={valtahun}
                max={year}
                min={1900}
                onClick={handleClickDefault}
                onChange={onChangeTahun}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  if (["e", ".", ","].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              ></input>

              <br />
              <span className="span-items">ISBN</span>
              <input
                type="text"
                className={redISBN ? "inputText red" : "inputText"}
                value={valisbn}
                // onChange={(e) => setValIsbn(e.target.value)}
                onChange={handleInputISBN}
                onClick={handleClickDefault}
                placeholder="Input salah satu ISBN disini"
              ></input>

              <br />
              <span className="span-items">DOI</span>
              <input
                type="text"
                className={redDoi ? "inputText red" : "inputText"}
                value={valdoi}
                onChange={handleInputDOI}
                onClick={handleClickDefault}
                placeholder="Input salah satu DOI disini"
              ></input>
            </form>
          </form>

          <div className={redFile ? "footer-ebook red" : "footer-ebook"} onClick={handleClickDefault}>
            <span className="span-items">File PDF</span>
            <span className="required"> *</span>
            <input
              type="file"
              className="file-ebook"
              accept=".pdf"
              onChange={(e) => handleselectedFile(e)}
              // multiple
            />
          </div>

          <button className="uploadBut" onClick={(e) => handleClickUpload(e)}>
            Upload
          </button>
        </div>
      )}

      {props.loadingVis && (
        <div className="loading-container">
          <div
            className="logo-spinner"
            style={{ display: "flex", marginLeft: "0px" }}
          >
            <img
              className="logo-loading"
              src={logo}
              style={{ width: "320px", height: "120px" }}
            ></img>
            <img
              className="gif-loading"
              src={gifLogo}
              style={{ width: "60px", height: "60px", marginLeft: "10px" }}
            ></img>
          </div>
          <p
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
              fontSize: "15px",
              whiteSpace: "pre-line",
            }}
          >
            {props.status === "waiting"
              ? "Tunggu Antrean Extract Ebook (Waiting)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"
              : "Extract Ebook Anda sedang di Proses (Processing)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"}
          </p>

          <div className="footer-loading">
            <button className="ebookBaru_but" onClick={clickBaru}>
              EBOOK BARU
            </button>
          </div>
        </div>
      )}

      {loadingSpinner && (
        <div>
          <span style={{ fontSize: "15px" }}>
            Masih Belum Masuk Antrian (Tunggu Upload Selesai)
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "15px" }}>
              Progress Upload : {prosesUpload}% &nbsp;&nbsp;&nbsp;
            </span>
            <Spinner />
          </div>
        </div>
      )}

      {props.doneVis && (
        <div className="done_container">
          <div className="done_logo">
            <img src={logo} style={{ width: "320px", height: "120px" }}></img>
          </div>

          <span
            className="done_notif"
            style={{ color: "white", backgroundColor: "green" }}
          >
            UPLOAD EBOOK TELAH SELESAI
          </span>

          <div className="done_button">
            <button className="done_kembaliBut" onClick={clickBaru}>
              EBOOK BARU
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ebook;
