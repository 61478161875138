import React from "react";
// import DashboardCSS from "./Dashboard.module.css";
import "../Dashboard.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
import { BsGear, BsQuestionSquare } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrView } from "react-icons/gr";
import { GoHistory } from "react-icons/go";

const Pengaturan = () => {
  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <Link to={"/dashboard"} className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <FaUser className="fas" />
              <span className="nav-item">Requests</span>
            </Link>
          </li>
          <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="main-top-pengaturan">
          <div>
            <h2>Coming Soon (Settings Component)</h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pengaturan;
