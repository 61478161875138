import React from "react";
import "./PopUp.css"; // Import CSS file for styling

const PopUp = ({handleClose, show, judulContent, isiContent}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const handlePopupClick = (event) => {
    // Mencegah event bubbling ke elemen luar
    event.stopPropagation();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button className="close" onClick={handleClose}>
          x
        </button>
        <div onClick={handlePopupClick}>
          <h2>{judulContent}</h2>
          {isiContent}
        </div>
      </section>
    </div>
  );
};

export default PopUp;
