import React from "react";
import "./EditEbook.css"; // Import CSS file for styling

const EditEbook = ({handleClose, show}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const handlePopupClick = (event) => {
    // Mencegah event bubbling ke elemen luar
    event.stopPropagation();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button className="close" onClick={handleClose}>
          x
        </button>
        <div onClick={handlePopupClick}>
          <h3>Edit Ebook</h3>
          <div>
            <span>Judul Ebook</span>
            <input type="text" style={{border:"1px solid black"}}></input>
          </div>
          <div>
            <span>Jenis Ebook</span>
            <input type="text" style={{border:"1px solid black"}}></input>
          </div>
          <div>
            <span>Author</span>
            <input type="text" style={{border:"1px solid black"}}></input>
          </div>
          <div>
            <span>Penerbit</span>
            <input type="text" style={{border:"1px solid black"}}></input>
          </div>

          <button style={{marginRight:"10px"}}>Edit</button>
          <button>Delete</button>
        </div>
      </section>
    </div>
  );
};

export default EditEbook;
