import React, { useState, useEffect } from "react";
import "./Toast.css";

const Toast = ({ onClose, isiNotif }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(() => {
        onClose(); // Panggil fungsi onClose setelah notifikasi otomatis hilang
      }, 200);
    }, 2000); // 2 detik sebelum notifikasi menghilang

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`notification ${isVisible ? "" : "slideOut"}`}>
      <button
        className="close_notif"
        onClick={() => {
          setIsVisible(false);
          onClose();
        }}
      >
        x
      </button>

      <div>
        <h1>INFO</h1>
        {isiNotif}
      </div>
    </div>
  );
};

export default Toast;
