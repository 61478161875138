import React, { useState, useEffect } from "react";
import "./Question.css";
import DropFileInput from "./DropFileInput.js";

const Question = (props) => {
  const data = "Generate";

  const onFileChange = (files) => {
    console.log(files);
  };

  const handleClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    props.getOverflow("hidden");
  }, []);
  
  useEffect(() => {
    console.log("LOADING VIS REVIEW : ", props.loadingVis);
  }, [])

  return (
    <div className="questDash" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
      <h1 id="generateH1">SEDANG PERBAIKAN</h1>
      {/* <DropFileInput
        className="dropFile"
        onFileChange={(files) => onFileChange(files)}
        textjudul={data}
        width="1000"
        height="100"
        widthlogo="25"
        logoName="pptx.png"
        dropName="PPTX"
        accept=".pptx"
        multiple={false}
      /> */}
      {/* 
      <span id='prompt'>Prompt</span>
      <input type='text' id='inputPrompt'></input> */}

      {/* <button className="questBut" onClick={(e) => handleClick(e)}>
        Generate
      </button> */}
    </div>
  );
};

export default Question;
