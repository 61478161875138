import React from "react";
// import DashboardCSS from "./Dashboard.module.css";
import "./Dashboard.css";
import Popup from ".//pages/PopUp.js";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../LoginAssets/itdri.png";
// import avatar from "../Dashboard/assets/marshall.png";
import Avatar from "@mui/material/Avatar";
import { BsGear, BsQuestionSquare } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineHistory } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrView } from "react-icons/gr";
import { GoHistory } from "react-icons/go";
import { LuBookDown, LuContact2 } from "react-icons/lu";
import { LuFileQuestion } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { MdSpatialAudio } from "react-icons/md";
import Review from "../Dashboard/pages/Review.js";
import Question from "../Dashboard/pages/Question.js";
import Loading from "../Dashboard/pages/Loading.js";
import Module from "../Dashboard/pages/Module.js";
import Ebook from "../Dashboard/pages/Ebook.js";
import axios from "axios";
import { red } from "@mui/material/colors";
import Joyride from "react-joyride";

const { useState, useEffect } = React;

const Dashboard = (props) => {
  /////////////////////////////////////////////////////////////////////
  // VARIABLE

  // State untuk menjalankan tutorial
  const [run, setRun] = useState(false);

  // Langkah-langkah tutorial
  const steps = [
    {
      target: ".btn-review", // Selector untuk elemen
      content: "Lakukan Review Module mu di sini",
    },
    {
      target: ".btn-gmodul", // Selector untuk elemen
      content: "Generate Module dapat di akses di sebelah sini",
    },
    {
      target: ".btn-history", // Selector untuk elemen
      content: "Dan untuk Upload Ebook dapat di akses di sebelah sini",
    },
    
  ];

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  // API
  const [all_grup_kompetensi, setAll_grup_kompetensi] = useState([]);
  const [nama_kompetensi, setNama_kompetensi] = useState([]);
  const [namauser, setNamaUser] = useState([]);
  var grupArray = [];

  // Jika Button di Klik, maka btnState = True
  const [btnStateReview, setBtnReview] = useState(true);
  const [btnStateQuest, setBtnQuest] = useState(false);
  const [btnStateModul, setBtnModul] = useState(false);
  const [btnStateEbook, setBtnEbook] = useState(false);

  // Hidden Content
  const [reviewVis, setReviewVis] = useState(true);
  const [questVis, setQuestVis] = useState(false);
  const [moduleVis, setModuleVis] = useState(false);
  const [ebookVis, setEbookVis] = useState(false);

  // Jika btnState True, tambah class active
  let toggleReview = btnStateReview ? " active" : " disable";
  let toggleGquest = btnStateQuest ? " active" : " disable";
  let toggleGmodul = btnStateModul ? " active" : " disable";
  let toggleHistory = btnStateEbook ? " active" : " disable";

  // VARIABLE SAVE REVIEW
  const [showcancel, setShowCancel] = useState();
  const [kontenVisReview, setKontenVisReview] = useState(true);
  const [loadingVisReview, setLoadingVisReview] = useState(false);
  const [tableVis, setTableVis] = useState(false);
  const [tokenReview, setTokenReview] = useState("");
  const [statusReview, setStatusReview] = useState("");
  const [headerShow, setHeaderShow] = useState("flex");
  // const [nilaiVis, setNilaiVis] = useState();

  const toggleVisReview = (type, data) => {
    if (type == "content") {
      setKontenVisReview(data);
    } else if (type == "loading") {
      setLoadingVisReview(data);
    } else if (type === "table") {
      setTableVis(data);
    } else if (type === "token") {
      setTokenReview(data);
    } else if (type === "status") {
      setStatusReview(data);
    }
  };

  // VARIABLE SAVE MODULE
  const [kontenVisModule, setKontenVisModule] = useState(true);
  const [loadingVisModule, setLoadingVisModule] = useState(false);
  const [doneVisModule, setDoneVisModule] = useState(false);
  const [tokenGenerate, setTokenGenerate] = useState("");
  const [statusGenerate, setStatusGenerate] = useState("");

  const toggleVisModule = (type, data) => {
    if (type == "content") {
      setKontenVisModule(data);
    } else if (type == "loading") {
      setLoadingVisModule(data);
    } else if (type === "done") {
      setDoneVisModule(data);
    } else if (type === "token") {
      setTokenGenerate(data);
    } else if (type === "status") {
      setStatusGenerate(data);
    }
  };

  // VARIABLE SAVE EBOOK
  const [kontenVisEbook, setKontenVisEbook] = useState(true);
  const [loadingVisEbook, setLoadingVisEbook] = useState(false);
  const [doneVisEbook, setDoneVisEbook] = useState(false);
  const [tokenEbook, setTokenEbook] = useState("");
  const [statusEbook, setStatusEbook] = useState("");

  const toggleVisEbook = (type, data) => {
    if (type == "content") {
      setKontenVisEbook(data);
    } else if (type == "loading") {
      setLoadingVisEbook(data);
    } else if (type === "done") {
      setDoneVisEbook(data);
    } else if (type === "token") {
      setTokenEbook(data);
    } else if (type === "status") {
      setStatusEbook(data);
    }
  };

  // VARIABLE KEBUTUHAN REQUEST
  const [reqShow, setReqShow] = useState();
  const [typeShow, setTypeShow] = useState();

  // RESET TOKEN
  const [resetTokenReview, setResetTokenReview] = useState();
  const [resetStatusReview, setResetStatusReview] = useState();
  const [resetTokenGenerate, setResetTokenGenerate] = useState();
  const [resetStatusGenerate, setResetStatusGenerate] = useState();

  // Avatar
  const [avatarText, setAvatarText] = useState("Marshall");
  const [styleAvText, setStyleAvText] = useState();
  const [marginBtn, setMarginBtn] = useState();

  // Overflow Table
  const [overflowTable, setOverflowTable] = useState("hidden");

  const getOverflow = (isi) => {
    setOverflowTable(isi);
  };

  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  // FUNCTION

  /////////////////////////////////////////////////////////////////////
  // USE EFFECT

  // RESET ALL TOKEN
  useEffect(() => {
    props.reset();
  }, []);

  const getAllKompetensi = async (maxAttempts) => {
    let arrayStore = [];
    let attempts = 0;
    while (arrayStore.length === 0 && attempts < maxAttempts) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/all-grup-kompetensi/",
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (
          response.data.list_grup_kompetensi &&
          response.data.list_grup_kompetensi.length > 0
        ) {
          let data = response.data.list_grup_kompetensi;

          for (let i = 0; i < data.length; i++) {
            arrayStore.push(data[i][1]);
          }
          console.log("All Grup Kompetensi : " + arrayStore);
        } else {
          console.log("Data kosong, mencoba lagi...");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      attempts += 1;
    }

    if (arrayStore.length === 0) {
      console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
    }

    return arrayStore;
  };

  // AMBIL DATA ALL GRUP KOMPETENSI DARI API
  // useEffect(() => {

  //   function getAllKompetensi() {
  //     // grupArray.slice();
  //     let arrayStore = [];
  //     axios({
  //       timeout: 360000,
  //       method: "post",
  //       url: process.env.REACT_APP_API_URL+"/all-grup-kompetensi/",
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //       .then(function (res) {
  //         let data = res.data.list_grup_kompetensi;
  //         console.log("All Grup Kompetensi : " + data);

  //         for (let i = 0; i < data.length; i++) {
  //           arrayStore.push(data[i][1]);
  //         }

  //         console.log(arrayStore.length);
  //         setAll_grup_kompetensi(arrayStore);
  //       })
  //       .catch(function (res) {
  //         console.log(res);
  //         return [];
  //       });
  //   }

  //   getAllKompetensi();

  //   // setAll_grup_kompetensi(hasil);

  // }, []);

  useEffect(() => {
    const getData = async () => {
      const hasil = await getAllKompetensi(5);
      setAll_grup_kompetensi(hasil);
      // console.log("INI HASILNYAAAA ", hasil);
    };

    getData();
  }, []);

  // SETTING AVATAR
  useEffect(() => {
    if (props.user.nama.length > 5) {
      if (props.user?.role == null || props.user?.role == "non-school") {
        const tambah = 12 * (props.user.nama.length - 5);
        setStyleAvText((162 + tambah).toString() + "px");
        // setMarginBtn((400 - tambah).toString() + "px");
      } else {
        const tambah =
          12 * (props.user.nama.length + props.user.role.length - 5);
        setStyleAvText((162 + tambah).toString() + "px");
        // setMarginBtn((400 - tambah).toString() + "px");
      }
    } else {
      if (props.user?.role == null || props.user?.role == "non-school") {
        setStyleAvText("162px");
        // setMarginBtn("400px");
      } else {
        const tambah = 12 * (props.user.role.length + 1);
        setStyleAvText((162 + tambah).toString() + "px");
        // setMarginBtn("400px");
      }
    }
  }, []);

  // SETTING TOKEN RESET
  useEffect(() => {
    console.log("RESET TOKENNNNNNNNN? : ", resetTokenReview);
    console.log("RESET STATUSSSSSSSS? : ", resetStatusReview);
    if (resetTokenReview == "RESET") {
      props.resetTokenReview(resetTokenReview);
      props.resetStatusReview(resetStatusReview);
    }
  }, [resetTokenReview]);

  useEffect(() => {
    console.log("RESET TOKENNNNNNNNN? : ", resetTokenGenerate);
    console.log("RESET STATUSSSSSSSS? : ", resetStatusGenerate);
    if (resetTokenGenerate == "RESET") {
      props.resetTokenGenerate(resetTokenReview);
      props.resetStatusGenerate(resetStatusReview);
    }
  }, [resetTokenGenerate]);

  // SETTING RESET REQUEST SHOW
  useEffect(() => {
    console.log("RESET REQ SHOW : ", reqShow);
    props.resetShow(reqShow);
  }, [reqShow]);

  // SETTING RESET TYPE
  useEffect(() => {
    console.log("RESET TYPE : ", typeShow);
    props.resetType(typeShow);
  }, [typeShow]);

  // CEK YANG DI SHOW APA
  useEffect(() => {
    console.log("TYPE SHOW DARI REQUEST : ", props.typeShow);
    if (props.typeShow === "review") {
      console.log("SHOW : REVIEW MODUL");

      setReviewVis(true);
      setQuestVis(false);
      setModuleVis(false);
      setEbookVis(false);

      setBtnReview(true);
      setBtnModul(false);
    } else if (props.typeShow === "generate") {
      console.log("SHOW : GENERATE MODUL");

      setReviewVis(false);
      setQuestVis(false);
      setModuleVis(true);
      setEbookVis(false);

      setBtnReview(false);
      setBtnModul(true);
    }
  }, [props.reqShow]);

  /////////////////////////////////////////////////////////////////////////

  // TAMPUNG VARIABLE DI MODULE
  const tampungModule = {
    loadingVis: loadingVisModule,
    kontenVis: kontenVisModule,
    doneVis: doneVisModule,
    tokenGenerate: tokenGenerate,
    statusGenerate: statusGenerate,
  };

  ////////////////////////////////////////

  // TAMPUNG VARIABLE DI REVIEW

  const tampungReview = {
    showcancel: showcancel,
    loadingVis: loadingVisReview,
    tableVis: tableVis,
    // nilaiVis: nilaiVis,
    headerShow: headerShow,
    kontenVis: kontenVisReview,
    tokenReview: tokenReview,
    statusReview: statusReview,
  };

  ////////////////////////////////////////

  // const getDataModule = (item_id, data) => {
  //   if (item_id === "kontenVis") {
  //     setKontenVisModule(data);
  //   } else if (item_id === "loadingVis") {
  //     setLoadingVisModule(data);
  //   } else if (item_id === "doneVis") {
  //     setDoneVisModule(data);
  //   } else if (item_id === "tokenGenerate") {
  //     setTokenGenerate(data);
  //   } else if (item_id === "statusGenerate") {
  //     setStatusGenerate(data);
  //   } else if (item_id === "reqShow") {
  //     setReqShow(data);
  //   }

  //   console.log("TERPANGGILLLLL", item_id + data);
  // };

  // GET DARI REVIEW

  // const getDataReview = (item_id, data) => {
  //   if (item_id === "showcancel") {
  //     setShowCancel(data);
  //   } else if (item_id === "loadingVis") {
  //     setLoadingVisReview(data);
  //   } else if (item_id === "tableVis") {
  //     setTableVis(data);
  //   }
  //   // else if (item_id === "nilaiVis") {
  //   //   setNilaiVis(data);
  //   // }
  //   else if (item_id === "headerShow") {
  //     setHeaderShow(data);
  //   } else if (item_id === "kontenVis") {
  //     setKontenVisReview(data);
  //   } else if (item_id === "tokenReview") {
  //     setTokenReview(data);
  //   } else if (item_id === "statusReview") {
  //     setStatusReview(data);
  //   } else if (item_id === "reqShow") {
  //     setReqShow(data);
  //   } else if (item_id === "typeShow") {
  //     setTypeShow(data);
  //   }
  // };

  const handleClickReview = (e) => {
    e.preventDefault();

    if (btnStateReview !== true) {
      setBtnReview((btnStateReview) => !btnStateReview);
      setReviewVis(true);
      setQuestVis(false);
      setModuleVis(false);
      setEbookVis(false);

      if (btnStateQuest === true) {
        setBtnQuest((btnStateQuest) => !btnStateQuest);
      }
      if (btnStateModul === true) {
        setBtnModul((btnStateModul) => !btnStateModul);
      }
      if (btnStateEbook === true) {
        setBtnEbook((btnStateEbook) => !btnStateEbook);
      }
    } else {
      // Nothing
    }
  };

  //Hide Notif
  const [notif, setNotif] = useState(false);
  const showHideNotif = notif ? " display-block" : " display-none";

  const onClickAvatar = () => {
    //const text = "Marshall";
    setNotif(true);

    if (props.user.nama.length > 5) {
      if (props.user?.role == null || props.user?.role == "non-school") {
        const tambah = 12 * (props.user.nama.length - 5);
        setStyleAvText((162 + tambah).toString() + "px");
        // setMarginBtn((400 - tambah).toString() + "px");
      } else {
        const tambah = 12 * (props.user.nama.length + props.user.role.length);
        setStyleAvText((162 + tambah).toString() + "px");
        // setMarginBtn((400 - tambah).toString() + "px");
      }
    } else {
      if (props.user?.role == null || props.user?.role == "non-school") {
        setStyleAvText("162px");
        // setMarginBtn("400px");
      } else {
        const tambah = 12 * props.user.role.length;
        setStyleAvText((162 + tambah).toString() + "px");
        // setMarginBtn("400px");
      }
    }

    //setAvatarText(text);
  };

  // SETTING INISIAL VARIABLE (AGAR PAS UBAH KE REQUEST, TAMPILAN TIDAK BERUBAH)
  // useEffect(() => {
  //   function initialDataApp() {
  //     setShowCancel(props.showcancel);

  //     setLoadingVisReview(props.loadingVis);
  //     setTableVis(props.tableVis);
  //     setNilaiVis(props.nilaiVis);

  //     setHeaderShow(props.headerShow);
  //     setKontenVisReview(props.kontenVis);

  //     setTokenModule(props.tokenModule);

  //     console.log("TOKEN DARI APP : " + props.tokenModule)
  //   }

  //   initialDataApp();
  // }, []);

  const handleClickQuest = (e) => {
    e.preventDefault();

    if (btnStateQuest !== true) {
      setBtnQuest((btnStateQuest) => !btnStateQuest);
      setQuestVis(true);
      setReviewVis(false);
      setModuleVis(false);
      setEbookVis(false);

      if (btnStateReview === true) {
        setBtnReview((btnStateReview) => !btnStateReview);
      }
      if (btnStateModul === true) {
        setBtnModul((btnStateModul) => !btnStateModul);
      }
      if (btnStateEbook === true) {
        setBtnEbook((btnStateEbook) => !btnStateEbook);
      }
    } else {
      // Nothing
    }
  };
  const handleClickModul = (e) => {
    e.preventDefault();

    if (btnStateModul !== true) {
      setBtnModul((btnStateModul) => !btnStateModul);
      setModuleVis(true);
      setReviewVis(false);
      setQuestVis(false);
      setEbookVis(false);

      if (btnStateReview === true) {
        setBtnReview((btnStateReview) => !btnStateReview);
      }
      if (btnStateQuest === true) {
        setBtnQuest((btnStateQuest) => !btnStateQuest);
      }
      if (btnStateEbook === true) {
        setBtnEbook((btnStateEbook) => !btnStateEbook);
      }
    } else {
      // Nothing
    }
  };

  const handleClickHistory = (e) => {
    e.preventDefault();

    if (btnStateEbook !== true) {
      setEbookVis(true);
      setReviewVis(false);
      setQuestVis(false);
      setModuleVis(false);

      setBtnEbook((btnStateEbook) => !btnStateEbook);
      if (btnStateReview === true) {
        setBtnReview((btnStateReview) => !btnStateReview);
      }
      if (btnStateQuest === true) {
        setBtnQuest((btnStateQuest) => !btnStateQuest);
      }
      if (btnStateModul === true) {
        setBtnModul((btnStateModul) => !btnStateModul);
      }
    } else {
      // Nothing
    }
  };

  // Help Button
  const [showPopup, setShowPopup] = useState(false);

  const onClickHelp = (e) => {
    e.preventDefault();

    setShowPopup(!showPopup);
  };

  // New Update
  const stringToInitials = (name) => {
    const initials = name
      .split(" ")
      .map((n) => n[0])
      .filter((_, index) => index < 2)
      .join("");
    return initials.toUpperCase();
  };

  const UserAvatar = ({ username }) => {
    return (
      <Avatar
        sx={{
          bgcolor: "var(--itdri1)",
          color: "white",
          width: "40px",
          height: "40px",
          padding: "2px",
          marginTop: "5px",
          marginRight: "5px",
        }}
      >
        {stringToInitials(username)}
      </Avatar>
    );
  };

  /////////////////////////////////////////////////////////////////////

  return (
    <div className="container">
      <Joyride
        locale={{
          skip: "Lewati",
          next: "Lanjut",
          back: "Kembali",
          last: "Selesai"
        }}
        steps={steps}
        run={run}
        continuous
        hideCloseButton
        disableOverlayClose
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li id="create">
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li id="requests">
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">View Requests History</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li> */}
          <li id="elibrary">
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li id="coqa">
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li id="mapping">
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li id="audiolearning">
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li id="contactus">
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas-logout" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="header_dash">
          <div
            className="div-btn-dashboard"
            style={{ left: "2%", display: "flex", position: "absolute" }}
          >
            <button
              className={`btn-review${toggleReview}`}
              onClick={handleClickReview}
            >
              <GrView className="icon" />
              <span>Review</span>
            </button>
            {/* <button
              className={`btn-gquest${toggleGquest}`}
              onClick={handleClickQuest}
            >
              <BsQuestionSquare className="icon" />
              <span>Question</span>
            </button> */}
            <button
              className={`btn-gmodul${toggleGmodul}`}
              onClick={handleClickModul}
            >
              <HiMenuAlt3 className="icon" />
              <span>Module</span>
            </button>
            <button
              className={`btn-history${toggleHistory}`}
              onClick={handleClickHistory}
            >
              <GoHistory className="icon" />
              <span>Ebook</span>
            </button>
          </div>

          {/* New Update */}

          <div className="avatar_item" style={{ width: styleAvText }}>
            <span className="avatar_msg">
              Hai,{" "}
              {props.user?.role == null || props.user?.role == "non-school"
                ? props.user?.nama
                : props.user?.nama + " (" + props.user?.role + ")"}
            </span>
            <UserAvatar username={props.user?.nama} />
            {/* <img src={logo} alt="Avatar" className="avatar_img" />
            <Popup
              handleClose={onClickHelp}
              show={showPopup}
              judulContent={"Notifikasi"}
              isiContent={
                <div>
                  <p>
                    (1) Proses Review - namaPPT.pptx SUDAH SELESAI. (12.00 WIB)
                  </p>
                  <p>(2) Proses Generate Module SUDAH SELESAI. (11.00 WIB)</p>
                </div>
              }
            />
            <span className={`notifText ${showHideNotif}`}>2</span> */}
          </div>
        </div>

        <div className="main-top">
          <div className="form-isi" style={{ overflowY: overflowTable }}>
            {reviewVis && (
              <Review
                userid={props.user.id_user}
                username={props.user.nama}
                tokenReq={props.tokenReview}
                statusReq={props.statusReview}
                resetToken={setResetTokenReview}
                resetStatus={setResetStatusReview}
                // reqShow={props.reqShow}
                // typeShow={props.typeShow}

                kontenVis={kontenVisReview}
                loadingVis={loadingVisReview}
                tableVis={tableVis}
                token={tokenReview}
                status={statusReview}
                toggleVisReview={toggleVisReview}
                // getDataReview={getDataReview}
                // {...tampungReview}
                getOverflow={getOverflow}
              />
            )}
            {questVis && <Question getOverflow={getOverflow} />}
            {moduleVis && (
              <Module
                userid={props.user.id_user}
                username={props.user.nama}
                tokenReq={props.tokenGenerate}
                statusReq={props.statusGenerate}
                resetToken={setResetTokenGenerate}
                resetStatus={setResetStatusGenerate}
                kontenVis={kontenVisModule}
                loadingVis={loadingVisModule}
                doneVis={doneVisModule}
                token={tokenGenerate}
                status={statusGenerate}
                toggleVisModule={toggleVisModule}
                grupKomlist={all_grup_kompetensi}
                // getDataModule={getDataModule}
                // {...tampungModule}
                getOverflow={getOverflow}

                // reqShow={props.reqShow}
              />
            )}
            {ebookVis && (
              <Ebook
                userid={props.user.id_user}
                username={props.user.nama}
                getOverflow={getOverflow}
                grupKomlist={all_grup_kompetensi}
                kontenVis={kontenVisEbook}
                loadingVis={loadingVisEbook}
                doneVis={doneVisEbook}
                token={tokenEbook}
                status={statusEbook}
                toggleVisEbook={toggleVisEbook}
              />
            )}
          </div>
        </div>
      </section>
      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default Dashboard;
