import React, { useState, useEffect } from "react";
import "./VideoLearning.css";
import DropFileInput from "./DropFileInput.js";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
// import video from "../assets/marshall.mp4"
import { BsGear, BsQuestionSquare } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import { LuContact2, LuFileQuestion } from "react-icons/lu";
import { LuBookDown } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import { MdSpatialAudio } from "react-icons/md";

const VideoLearning = (props) => {
  // RESET ALL TOKEN
  useEffect(() => {
    props.reset();
  }, []);
  
  const MainPageVideoLearning = () => {
    const [kontenVis, setKontenVis] = useState(true);
    const [videoVis, setVideoVis] = useState(false);

    // File
    const [files, setFiles] = useState("");

    //////////////////////////////////////////////////////////////////
    const onFileChangeWav = (e) => {
      setFiles(e[0]);
      console.log("Hasil upload PPTX : ", e[0]);
    };

    const onFileChangePPT = (e) => {
      setFiles(e[0]);
      console.log("Hasil upload PPTX : ", e[0]);
    };

    const handleClickGenerate = (e) => {
      // Something
      e.preventDefault();
      setKontenVis(false);
      setVideoVis(true);
    };

    const handleClickGenerateBaru = (e) => {
      // Something
      e.preventDefault();
      setKontenVis(true);
      setVideoVis(false);
    };

    return (
      <div className="fullpage_video">
        {kontenVis && (
          <div>
            <div className="file_video_content">
              <div className="file_items1">
                <div style={{ display: "flex" }}>
                  <span className="span-items_video">File Module PPT</span>
                  <span className="span-items_video" style={{ color: "red" }}>
                    *
                  </span>
                </div>
                <DropFileInput
                  onFileChange={(e) => onFileChangePPT(e)}
                  textjudul="CoQa"
                  width="500"
                  height="100"
                  widthlogo="25"
                  logoName="pptx.png"
                  dropName="PPTX"
                  accept=".pptx"
                  multiple={false}
                />
              </div>

              <div className="file_items2">
                <span className="span-items_video">
                  File Voice Changer (Optional)
                </span>
                <DropFileInput
                  onFileChange={(e) => onFileChangeWav(e)}
                  textjudul="CoQa"
                  width="500"
                  height="100"
                  widthlogo="25"
                  logoName="file.png"
                  dropName="WAV"
                  accept=".wav"
                  multiple={false}
                />
              </div>
            </div>

            <div className="btnVideo">
              <button
                className="videoBut"
                onClick={(e) => handleClickGenerate(e)}
              >
                Generate Video
              </button>
            </div>
          </div>
        )}

        {videoVis && (
          <div className="video_content">
            <video className="responsive-video" controls>
              <source
                src="http://149.28.136.221:3001/static/media/marshall.67b24cccce95dc3998b4.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            <div className="btnVideo">
              <button
                className="videoBut"
                onClick={(e) => handleClickGenerateBaru(e)}
              >
                Generate Baru
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li>
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">Requests</span>
            </Link>
          </li>
          <li>
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li>
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li>
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li>
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li>
          <li>
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="main-videolearning">
          <div className="container_video_learning">
            <h1 id="judul_video_learning">Generate Video Learning</h1>
            <MainPageVideoLearning />
          </div>
        </div>
      </section>

      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default VideoLearning;
