import "./App.css";
import React, { useEffect, useState } from "react";
import Maintenance from "./Components/Maintenance.js"
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Requests from "./Components/Dashboard/pages/Requests";
import Pengaturan from "./Components/Dashboard/pages/Pengaturan";
import ProtectedRoute from "./ProtectedRoute";

// Import React Router DOM
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ELibrary from "./Components/Dashboard/pages/E-library";
import CoQa from "./Components/Dashboard/pages/CoQa";
import VideoLearning from "./Components/Dashboard/pages/VideoLearning";
import Mapping from "./Components/Dashboard/pages/Mapping";
import AudioLearning from "./Components/Dashboard/pages/AudioLearning";
import ContactUs from "./Components/Dashboard/pages/ContactUs";

// Post Hog Analisis
import posthog from "posthog-js";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const [tokenReview, setTokenReview] = useState("");
  const [tokenGenerate, setTokenGenerate] = useState("");
  const [tokenMapping, setTokenMapping] = useState("");
  const [statusReview, setStatusReview] = useState("");
  const [statusGenerate, setStatusGenerate] = useState("");
  const [statusMapping, setStatusMapping] = useState("");
  const [detailMapping, setDetailMapping] = useState("");

  const [tokenAudio, setTokenAudio] = useState("");
  const [statusAudio, setStatusAudio] = useState("");
  const [detailAudio, setDetailAudio] = useState("");

  const [tokenCoqa, setTokenCoqa] = useState("");
  const [statusCoqa, setStatusCoqa] = useState("");
  const [detailCoqa, setDetailCoqa] = useState("");

  const [reqShow, setReqShow] = useState();
  const [typeShow, setTypeShow] = useState();

  const resetAll = () => {
    // Dashboard
    setTokenReview("RESET");
    setStatusReview("RESET");

    setTokenGenerate("RESET");
    setStatusGenerate("RESET");

    // CoQa
    setTokenCoqa("");

    // Mapping
    setTokenMapping("");

    // Audio Learning
    setTokenAudio("");

    // Video Learning
    // Belum Ada
  };

  const resetDash = () => {
    // CoQa
    setTokenCoqa("");

    // Mapping
    setTokenMapping("");

    // Audio Learning
    setTokenAudio("");

    // Video Learning
  };

  const resetCoqa = () => {
    // Dashboard
    setTokenReview("RESET");
    setStatusReview("RESET");

    setTokenGenerate("RESET");
    setStatusGenerate("RESET");

    // Mapping
    setTokenMapping("");

    // Audio Learning
    setTokenAudio("");

    // Video Learning
  };

  const resetMap = () => {
    // Dashboard
    setTokenReview("RESET");
    setStatusReview("RESET");

    setTokenGenerate("RESET");
    setStatusGenerate("RESET");

    // CoQa
    setTokenCoqa("");

    // Audio Learning
    setTokenAudio("");

    // Video Learning
  };

  const resetAudio = () => {
    // Dashboard
    setTokenReview("RESET");
    setStatusReview("RESET");

    setTokenGenerate("RESET");
    setStatusGenerate("RESET");

    // CoQa
    setTokenCoqa("");

    // Mapping
    setTokenMapping("");

    // Video Learning
  };

  const resetVideo = () => {
    // Dashboard
    setTokenReview("RESET");
    setStatusReview("RESET");

    setTokenGenerate("RESET");
    setStatusGenerate("RESET");

    // CoQa
    setTokenCoqa("");

    // Mapping
    setTokenMapping("");

    // Audio Learning
    setTokenAudio("");
  };

  // Router
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Login setIsAuthenticated={setIsAuthenticated} setUser={setUser} />
        // <Maintenance setIsAuthenticated={setIsAuthenticated} setUser={setUser} /> // ganti Login jika tidak mt
      ),
    },
    {
      path: "/register",
      element: <Register />
      // element: <ProtectedRoute><Register /></ProtectedRoute>, // hapus protectedroute jika tidak mt
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute
          element={
            <Dashboard
              user={user}
              tokenReview={tokenReview}
              tokenGenerate={tokenGenerate}
              statusReview={statusReview}
              statusGenerate={statusGenerate}
              resetTokenReview={setTokenReview}
              resetStatusReview={setStatusReview}
              resetTokenGenerate={setTokenGenerate}
              resetStatusGenerate={setStatusGenerate}
              reqShow={reqShow}
              typeShow={typeShow}
              resetShow={setReqShow}
              resetType={setTypeShow}
              reset={resetDash}
            />
          }
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/requests",
      element: (
        <ProtectedRoute
          element={
            <Requests
              user={user}
              tokenReview={setTokenReview}
              tokenGenerate={setTokenGenerate}
              statusReview={setStatusReview}
              statusGenerate={setStatusGenerate}
              tokenMapping={setTokenMapping}
              statusMapping={setStatusMapping}
              detailMapping={setDetailMapping}
              tokenAudio={setTokenAudio}
              statusAudio={setStatusAudio}
              detailAudio={setDetailAudio}
              tokenCoqa={setTokenCoqa}
              statusCoqa={setStatusCoqa}
              detailCoqa={setDetailCoqa}
              reqShow={setReqShow}
              typeShow={setTypeShow}
              reset={resetAll}
            />
          }
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/settings",
      element: (
        <ProtectedRoute
          element={<Pengaturan />}
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/coqa",
      element: (
        <ProtectedRoute
          element={
            <CoQa
              user={user}
              reset={resetCoqa}
              tokenCoqa={tokenCoqa}
              statusCoqa={statusCoqa}
              detailCoqa={detailCoqa}
              resetToken={setTokenCoqa}
              resetStatus={setStatusCoqa}
              resetDetail={setDetailCoqa}
            />
          }
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/eLibrary",
      element: (
        <ProtectedRoute
          element={<ELibrary reset={resetAll} />}
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/mapping",
      element: (
        <ProtectedRoute
          element={
            <Mapping
              user={user}
              tokenMapping={tokenMapping}
              statusMapping={statusMapping}
              detailMapping={detailMapping}
              resetToken={setTokenMapping}
              resetStatus={setStatusMapping}
              resetDetail={setDetailMapping}
              reset={resetMap}
            />
          }
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/audiolearning",
      element: (
        <ProtectedRoute
          element={
            <AudioLearning
              user={user}
              tokenAudio={tokenAudio}
              statusAudio={statusAudio}
              detailAudio={detailAudio}
              resetToken={setTokenAudio}
              resetStatus={setStatusAudio}
              resetDetail={setDetailAudio}
              reset={resetAudio}
            />
          }
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/videolearning",
      element: (
        <ProtectedRoute
          element={<VideoLearning user={user} reset={resetVideo} />}
          isAuthenticated={isAuthenticated}
        />
      ),
    },
    {
      path: "/contactus",
      element: (
        <ProtectedRoute
          element={<ContactUs user={user} reset={resetAll} />}
          isAuthenticated={isAuthenticated}
        />
      ),
    },
  ]);

  // Posthog
  // useEffect(() => {
  //   // Inisialisasi PostHog dengan kunci API dan URL instance
  //   posthog.init("phc_3ZncoCZf7VZMIU7wB3jzBkjtpC8oRwO59TuS1Of58WS", { api_host: "https://us.i.posthog.com/" });

  //   // Contoh penggunaan: Melacak event 'Page View'
  //   posthog.capture("Page View");

  //   // Membersihkan PostHog saat component di-unmount
  //   return () => {
  //     posthog.reset();
  //   };
  // }, []);
  

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
