import React from "react";
import "./StepLayout.css"

const StepLayout = ({step1_color, step2_color, step3_color, rec1_color, rec2_color}) => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          width: "100px",
        }}
      >
        <label style={{ fontSize: "14px", marginBottom: "2px" }}>
          Generating
        </label>
        <div
          className="lingkaran_generate"
          style={{ backgroundColor: step1_color }}
        ></div>
      </div>

      <div style={{ alignContent: "center", padding: "0 10px 0 10px" }}>
        <div
          className="kotak_generate"
          style={{ backgroundColor: rec1_color }}
        ></div>
      </div>

      <div
        style={{
          display: "grid",
          justifyContent: "center",
          width: "100px",
        }}
      >
        <label style={{ fontSize: "14px", marginBottom: "2px" }}>
          Processing
        </label>
        <div
          className="lingkaran_generate"
          style={{ backgroundColor: step2_color }}
        ></div>
      </div>

      <div style={{ alignContent: "center", padding: "0 10px 0 10px" }}>
        <div
          className="kotak_generate"
          style={{ backgroundColor: rec2_color }}
        ></div>
      </div>

      <div
        style={{
          display: "grid",
          justifyContent: "center",
          width: "100px",
        }}
      >
        <label style={{ fontSize: "14px", marginBottom: "2px" }}>
          Checking
        </label>
        <div
          className="lingkaran_generate"
          style={{ backgroundColor: step3_color }}
        ></div>
      </div>
    </div>
  );
};

export default StepLayout;
