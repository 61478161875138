import React, { useState, useEffect } from "react";
import "./E-library.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
import { MdLogout } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import { LuContact2, LuFileQuestion } from "react-icons/lu";
import { LuBookDown } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import Dropdown from "react-dropdown";
import Pagination from "./Pagination.js";
import axios from "axios";
import Popup from "./PopUp.js";
import Spinner from "./SpinnerDs.js";
import { MdSpatialAudio } from "react-icons/md";

const ELibrary = (props) => {
  // RESET ALL TOKEN
  useEffect(() => {
    props.reset();
  }, []);

  const MainPageListEbooks = () => {
    const [dataTable, setDataTable] = useState([]);
    const [dataBuku, setDataBuku] = useState();
    const [dataJurnal, setDataJurnal] = useState();
    const [dataAjar, setDataAjar] = useState();

    const nullData = [
      {
        id: "-",
        id_ebook: "-",
        jenis_ebook: "-",
        judul_ebook: "-",
        grup_kompetensi: "-",
        nama_kompetensi: "-",
        author: "-",
        penerbit: "-",
        tahun_terbit: "-",
        isbn: "-",
        doi: "-",
        bahasa: "-",
        filepath: "-",
      },
    ];

    const options_jenisEbook = [
      "Semua",
      `Buku (${dataBuku})`,
      `Jurnal (${dataJurnal})`,
      `Bahan Ajar (${dataAjar})`,
    ];

    const [valjenisEbook, setValJenisEbook] = useState("Semua");

    // Hitung jumlah total halaman
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const totalPages = Math.ceil(dataTable.length / itemsPerPage);

    const currentData = dataTable.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const [refresh, setRefresh] = useState(false);
    const [allData, setAllData] = useState([]);

    // Pop Up Image
    const [popUpEbook, setPopUpEbook] = useState(false);

    // Delay Awal
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Mengatur timeout untuk menyembunyikan pesan selama 1 detik
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500); // 0,5 detik

      // Bersihkan timer jika komponen di-unmount
      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      if (refresh) {
        setRefresh(false);
      }
    }, [refresh]);

    useEffect(() => {
      console.log("Ambil Table Ebooks");

      const formData = new FormData();
      formData.append("n_row", 9999);
      formData.append("page", 1);

      axios({
        timeout: 360000,
        method: "post",
        url: process.env.REACT_APP_API_URL + "/list-ebook/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          let data = res.data;

          const newData = data.map((item, index) => ({
            id: index + 1, // Incremental id starting from 1
            id_ebook: item.id_ebook,
            jenis_ebook: item.jenis_ebook,
            judul_ebook: item.judul_ebook,
            grup_kompetensi: item.grup_kompetensi,
            nama_kompetensi: item.nama_kompetensi,
            author: item.author,
            penerbit: item.penerbit,
            tahun_terbit: item.tahun_terbit,
            isbn: item.isbn,
            doi: item.doi,
            bahasa: item.bahasa,
            filepath: item.filepath,
          }));

          console.log(data);

          setAllData(newData);
          setDataTable(newData);

          const filterbuku = newData
            .filter(
              (item) =>
                item.jenis_ebook.toLowerCase() === "buku" ||
                item.jenis_ebook.toLowerCase() === "ebook" ||
                item.jenis_ebook.toLowerCase() === "e-book" ||
                item.jenis_ebook.toLowerCase() === "book"
            )
            .map((item) => {
              return { ...item, jenis_ebook: "Buku" };
            });

          console.log("Buku : ", filterbuku);
          setDataBuku(filterbuku.length);

          const filterjurnal = newData
            .filter(
              (item) =>
                item.jenis_ebook.toLowerCase() === "jurnal" ||
                item.jenis_ebook.toLowerCase() === "paper"
            )
            .map((item) => {
              return { ...item, jenis_ebook: "Jurnal" };
            });

          console.log("Jurnal : ", filterjurnal);
          setDataJurnal(filterjurnal.length);

          const filterbahanajar = newData
            .filter(
              (item) =>
                item.jenis_ebook.toLowerCase() !== "buku" &&
                item.jenis_ebook.toLowerCase() !== "ebook" &&
                item.jenis_ebook.toLowerCase() !== "jurnal" &&
                item.jenis_ebook.toLowerCase() !== "e-book" &&
                item.jenis_ebook.toLowerCase() !== "book" &&
                item.jenis_ebook.toLowerCase() !== "paper"
            )
            .map((item) => {
              return { ...item, jenis_ebook: "Bahan Ajar" };
            });

          console.log("Bahan Ajar : ", filterbahanajar);
          setDataAjar(filterbahanajar.length);
        })
        .catch(function (res) {
          console.log(res);
          return "";
        });
    }, []);

    useEffect(() => {
      console.log("All Data : ", allData);
      if (!refresh) {
        // Ambil semua jenis
        const filterbuku = allData
          .filter(
            (item) =>
              item.jenis_ebook.toLowerCase() === "buku" ||
              item.jenis_ebook.toLowerCase() === "ebook" ||
              item.jenis_ebook.toLowerCase() === "e-book" ||
              item.jenis_ebook.toLowerCase() === "book"
          )
          .map((item) => {
            return { ...item, jenis_ebook: "Buku" };
          });

        console.log("Buku : ", filterbuku);
        setDataBuku(filterbuku.length);

        const filterjurnal = allData
          .filter(
            (item) =>
              item.jenis_ebook.toLowerCase() === "jurnal" ||
              item.jenis_ebook.toLowerCase() === "paper"
          )
          .map((item) => {
            return { ...item, jenis_ebook: "Jurnal" };
          });

        console.log("Jurnal : ", filterjurnal);
        setDataJurnal(filterjurnal.length);

        const filterbahanajar = allData
          .filter(
            (item) =>
              item.jenis_ebook.toLowerCase() !== "buku" &&
              item.jenis_ebook.toLowerCase() !== "ebook" &&
              item.jenis_ebook.toLowerCase() !== "jurnal" &&
              item.jenis_ebook.toLowerCase() !== "e-book" &&
              item.jenis_ebook.toLowerCase() !== "book" &&
              item.jenis_ebook.toLowerCase() !== "paper"
          )
          .map((item) => {
            return { ...item, jenis_ebook: "Bahan Ajar" };
          });

        console.log("Bahan Ajar : ", filterbahanajar);
        setDataAjar(filterbahanajar.length);

        if (filter === "Buku") {
          if (search !== "") {
            // const searchData1 = filterbuku.filter((item) =>
            //   item.nama_kompetensi.toLowerCase().includes(search.toLowerCase())
            // );
            const allFilter = filterbuku.filter(
              (item) =>
                item.nama_kompetensi
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.judul_ebook.toLowerCase().includes(search.toLowerCase())
            );
            setDataTable(allFilter);
          } else {
            setDataTable(filterbuku);
          }
        } else if (filter === "Jurnal") {
          if (search !== "") {
            const allFilter = filterjurnal.filter(
              (item) =>
                item.nama_kompetensi
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.judul_ebook.toLowerCase().includes(search.toLowerCase())
            );
            setDataTable(allFilter);
          } else {
            setDataTable(filterjurnal);
          }
        } else if (filter === "Bahan Ajar") {
          if (search !== "") {
            const allFilter = filterbahanajar.filter(
              (item) =>
                item.nama_kompetensi
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.judul_ebook.toLowerCase().includes(search.toLowerCase())
            );
            setDataTable(allFilter);
          } else {
            setDataTable(filterbahanajar);
          }
        } else {
          if (search !== "") {
            const allFilter = allData.filter(
              (item) =>
                item.nama_kompetensi
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.judul_ebook.toLowerCase().includes(search.toLowerCase())
            );
            console.log(allFilter);
            setDataTable(allFilter);
          } else {
            setDataTable(allData);
          }
        }
      }
    }, [refresh]);

    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");

    const handleJenisChange = (e) => {
      console.log(e.value.split("(")[0].trim());
      setFilter(e.value.split("(")[0].trim());
      setCurrentPage(1);
      setRefresh(true);
    };

    const handleInputChange = (e) => {
      setSearch(e.target.value);
      setCurrentPage(1);
      setRefresh(true);
    };

    const [download, setDownlaod] = useState("");

    const downloadEbook = (e) => {
      e.preventDefault();
    };

    // Details Show
    const [hasilReadEbook, setHasilReadEbook] = useState([]);

    const handleClickDetails = (e, id) => {
      e.preventDefault();
      console.log("ID EBOOKS : ", id);

      setPopUpEbook(!popUpEbook);

      const formData = new FormData();
      formData.append("id_ebook", id);

      axios({
        timeout: 360000,
        method: "post",
        url: process.env.REACT_APP_API_URL + "/read-ebook/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          let data = res.data;
          console.log(data);

          const newData = {
            // id_ebook: data.id_ebook,
            judul_ebook: data.judul_ebook,
            jenis_ebook: data.jenis_ebook,
            author: data.author,
            penerbit: data.penerbit,
            tahun_terbit: data.tahun_terbit,
            bahasa: data.bahasa,
            isbn: data.isbn,
            doi: data.doi,
            link_download: data.link_download,
          };

          console.log(newData);
          setHasilReadEbook(newData);
        })
        .catch(function (res) {
          console.log(res);
          return "";
        });
    };

    // Shortcut Key Escape Pop Up
    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          handleClickDetails(event);
        }
      };

      // Menambahkan event listener untuk tombol Escape
      window.addEventListener("keydown", handleKeyDown);

      // Membersihkan event listener saat komponen dilepas
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleClickDetails]);

    // Set Download Spinner
    const [loadingDownload, setLoadingDown] = useState(false);

    // Download Button Ebook
    const downloadButEbook = (e, download) => {
      setLoadingDown(true);
      console.log(download);

      setTimeout(() => {
        // Setelah beberapa waktu, sembunyikan spinner dan tampilkan kembali tombol
        // const link = document.createElement("a");
        // link.href = download;
        // document.body.appendChild(link);
        // link.click();
        // link.remove();

        window.open(download, "_blank");
        setLoadingDown(false);
      }, 1000); // Ganti dengan waktu proses Anda
    };

    return (
      <div className="fullpage_listebooks">
        {isLoading ? (
          <div className="spinner_req">
            <Spinner />
          </div>
        ) : currentData.length < 1 ? (
          // <div
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     padding: "10px",
          //   }}
          // >
          //   <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          //     Data Tidak Di Temukan
          //   </span>
          // </div>
          <div>
            <div className="header_listebooks">
              <div className="item_jenisEbook">
                <span className="span-items_listebooks">Jenis Ebook</span>
                <Dropdown
                  className="dropDown-jenisEbook"
                  options={options_jenisEbook}
                  value={valjenisEbook}
                  onChange={(e) => handleJenisChange(e)}
                />
              </div>

              <div className="searchBox_listEbooks">
                <span className="span-items_listebooks">Search</span>
                <input
                  type="text"
                  className="inputText_listEbooks"
                  value={search}
                  onChange={handleInputChange}
                  placeholder="Masukkan nama kompetensi"
                ></input>
              </div>
            </div>
            <table className="table_listebooks" border="1">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Judul Buku</th>
                  <th>Jenis Ebook</th>
                  <th>Grup Kompetensi</th>
                  <th>Nama Kompetensi</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {nullData.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td style={{ width: "500px" }}>{item.judul_ebook}</td>
                    <td style={{ width: "200px" }}>{item.jenis_ebook}</td>
                    <td style={{ width: "400px" }}>{item.grup_kompetensi}</td>
                    <td style={{ width: "300px" }}>{item.nama_kompetensi}</td>
                    <td>
                      <button className="details_ebook" disabled>
                        {/* <a
                                href={`http://149.28.136.221:8110/${item.filepath}`}
                                style={{ textDecoration: "none", color: "white", display: download.length < 5 ? "none" : "flex"}}
                                target="_blank"
                                rel="noopener noreferrer"
                              > */}
                        Details
                        {/* </a> */}
                      </button>
                      <Popup
                        handleClose={handleClickDetails}
                        show={popUpEbook}
                        judulContent={
                          <div>
                            <h1>Details Ebook</h1>
                          </div>
                        }
                        isiContent={
                          <div style={{ marginTop: "10px", fontSize: "15px" }}>
                            <ul
                              style={{ listStyleType: "none", paddingLeft: 0 }}
                            >
                              {Object.entries(hasilReadEbook)
                                .filter(([key]) => key !== "link_download") // Filter out 'link_download'
                                .map(([key, value], index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: "flex",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        minWidth: "120px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key.replace("_", " ")}
                                    </span>
                                    <span>: {value}</span>
                                  </li>
                                ))}
                            </ul>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                className="download_ebook"
                                onClick={(e) =>
                                  downloadButEbook(
                                    e,
                                    hasilReadEbook.link_download
                                  )
                                }
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              className="pagination_req"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="pagination_req">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="header_listebooks">
              <div className="item_jenisEbook">
                <span className="span-items_listebooks">Jenis Ebook</span>
                <Dropdown
                  className="dropDown-jenisEbook"
                  options={options_jenisEbook}
                  value={valjenisEbook}
                  onChange={(e) => handleJenisChange(e)}
                />
              </div>

              <div className="searchBox_listEbooks">
                <span className="span-items_listebooks">Search</span>
                <input
                  type="text"
                  className="inputText_listEbooks"
                  value={search}
                  onChange={handleInputChange}
                  placeholder="Masukkan nama kompetensi"
                ></input>
              </div>
            </div>
            <table className="table_listebooks" border="1">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Judul Buku</th>
                  <th>Jenis Ebook</th>
                  <th>Grup Kompetensi</th>
                  <th>Nama Kompetensi</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td style={{ width: "500px" }}>{item.judul_ebook}</td>
                    <td style={{ width: "200px" }}>{item.jenis_ebook}</td>
                    <td style={{ width: "400px" }}>{item.grup_kompetensi}</td>
                    <td style={{ width: "300px" }}>{item.nama_kompetensi}</td>
                    <td>
                      <button
                        className="details_ebook"
                        onClick={(e) => handleClickDetails(e, item.id_ebook)}
                      >
                        {/* <a
                                href={`http://149.28.136.221:8110/${item.filepath}`}
                                style={{ textDecoration: "none", color: "white", display: download.length < 5 ? "none" : "flex"}}
                                target="_blank"
                                rel="noopener noreferrer"
                              > */}
                        Details
                        {/* </a> */}
                      </button>
                      <Popup
                        handleClose={handleClickDetails}
                        show={popUpEbook}
                        judulContent={
                          <div>
                            <h1>Details Ebook</h1>
                          </div>
                        }
                        isiContent={
                          <div style={{ marginTop: "10px", fontSize: "15px" }}>
                            <ul
                              style={{ listStyleType: "none", paddingLeft: 0 }}
                            >
                              {Object.entries(hasilReadEbook)
                                .filter(([key]) => key !== "link_download") // Filter out 'link_download'
                                .map(([key, value], index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: "flex",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        minWidth: "120px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key.replace("_", " ")}
                                    </span>
                                    <span>: {value}</span>
                                  </li>
                                ))}
                            </ul>

                            {!loadingDownload ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  className="download_ebook"
                                  onClick={(e) =>
                                    downloadButEbook(
                                      e,
                                      hasilReadEbook.link_download
                                    )
                                  }
                                >
                                  Download
                                </button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Spinner />
                              </div>
                            )}
                          </div>
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              className="pagination_req"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="pagination_req">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li id="create">
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li id="requests">
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">View Requests History</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li> */}
          <li id="elibrary">
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li id="coqa">
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li id="mapping">
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li id="audiolearning">
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li id="contactus">
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas-logout" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="main-listebooks">
          <div className="container_list_ebooks">
            <h1 id="judul_list_ebooks">E-Library</h1>
            <MainPageListEbooks />
          </div>
        </div>
      </section>

      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default ELibrary;
